<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="!cardStatus"
          large
          class="white--text"
          color="green"
          rounded
          v-on="on"
          v-bind="attrs"
          >{{ text }} رصيد</v-btn
        >
      </template>
      <v-card>
        <v-card-title class="justify-center">
          <h1>{{ text }} رصيد</h1>
        </v-card-title>
        <v-card-text>
          <v-col>
            <input
            style="
              width: 252.22px;
              height: 35p;
              border: 1px solid #999;
              border-radius: 4px;
              padding: 9px;
              text-align: center;
            "
            type="date"
            v-model="date"
          />
          </v-col>
          <v-autocomplete
            :label="$t('hr.loan.cash')"
            outlined
            dense
            color="#757575"
            item-text="name"
            item-value="id"
            v-model="cash_account_id"
            no-filter
            :items="cashAccounts"
            :loading="cashAccountsLoading"
            v-debounce="cashAccAc"
          ></v-autocomplete>
          <v-text-field
            label="المبلغ"
            outlined
            dense
            @input="handleBalanceValue"
            v-model="amount"
            type="number"
          ></v-text-field>
          <v-text-field
            :label="$t('explain')"
            outlined
            dense
            v-model="note"
          ></v-text-field>
          <p v-if="balance">مجموع الرصيد : {{ balance.toLocaleString() }}</p>
          <p
            v-if="diplayErr"
            style="color: red; font-size: 1.1rem; font-weight: bold"
          >
            لا يوجد رصيد كافي لعملية السحب
          </p>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            :disabled="diplayErr"
            class="white--text"
            color="green"
            depressed
            @click="submit"
            :loading="btnLoading"
            >{{ text }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      note: null,
      cashAccountsLoading: false,
      cashAccounts: [],
      diplayErr: false,
      amount: null,
      cash_account_id: null,
      dialog: false,
      balance: this.totalBalance,
      btnLoading: false,
      dateDialog: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    };
  },
  props: {
    cardStatus: {
      type: Boolean,
    },
    text: {
      type: String,
    },
    cardId: {
      type: [Number, String],
    },
    totalBalance: {
      type: Number,
    },
    type: {
      type: String,
    },
  },
  methods: {
    async cashAccAc(value) {
      try {
        this.cashAccountsLoading = true;
        if(value){
        const searchResult = await axios.get(
          "accounting/account/auto-complete",
          {
            params: {
              query: value,
              cash: 1,
            },
          }
        );
        this.cashAccounts = searchResult.data.data;
        }
      } catch (err) {
      } finally {
        this.cashAccountsLoading = false;
      }
    },
    async submit() {
      try {
        this.btnLoading = true;
        let requestBody = {
          buffet_card_id: this.cardId,
          cash_account_id: this.cash_account_id,
          amount: this.amount,
          type: this.type,
          date: this.date,
          note: this.note,
        };
        await axios.post(`/deposit-card-record/${this.type}`, requestBody);
        this.$Notifications(
          "تمت العملية بنجاح",
          { rtl: true, timeout: 1000 },
          "success"
        );
        this.dialog = false;
        this.amount = null;
        this.$emit("done");
      } catch (err) {
      } finally {
        this.btnLoading = false;
      }
    },
    handleBalanceValue(event) {
      if (this.text == "شحن") {
        this.balance = Number(this.totalBalance) + Number(event);
      } else {
        this.balance = Number(this.totalBalance) - Number(event);
      }
      if (this.balance < 0) {
        this.diplayErr = true;
      } else {
        this.diplayErr = false;
      }
    },
  },
};
</script>

<style>
</style>