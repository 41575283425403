<template>
  <v-container>
  <v-card  class="pt-5">
    <div v-if="loadingStatus" class="table__spinnerWrapper">
      <app-spinner class="mt-5 mr-5"></app-spinner>
    </div>
    <div v-else>
      <v-row class="mr-5 pt-3">
        <v-col md="4" class="py-0">
          <p style="font-size: 2.5rem">
            {{ $t("student name") }} : {{ student.firstName }}
          </p>
        </v-col>
        <v-col md="7" class="d-flex justify-end py-0">
          <div class="d-flex">
            <v-btn
              v-if="returnAbility('student:update')"
              color="green"
              class="white--text ml-3"
              rounded
              @click="$router.push(`/editStudent/${studentId}`)"
            >
              {{ $t("edit student") }}</v-btn
            >
            <app-edit-accept-student-modal
              @studentAcceptingEdited="getRegistrationRecord(studentId)"
              :studentData="student"
              :studentId="studentId"
              v-if="
                !registerStatus && returnAbility('registration-record:update')
              "
            ></app-edit-accept-student-modal>
            <app-accept-student-modal
              v-if="
                registerStatus && returnAbility('registration-record:store')
              "
              :studentId="studentId"
              @studentAccepted="getRegistrationRecord(studentId)"
            ></app-accept-student-modal>
            <v-btn
              v-if="!student.invoice && returnAbility('invoice:store')"
              color="green"
              class="white--text ml-5"
              rounded
              @click="$router.push(`/createInvoiceFromTemplate/student/${studentId}`)"
            >
              {{ $t("addBill") }}</v-btn
            >
            <v-btn
              v-if="student.invoice && returnAbility('invoice:show')"
              
              color="green"
              class="white--text ml-5"
              rounded
              @click="$router.push(`/edit-invoice/${student.invoice_id}`)"
            >
              {{ $t("displayBill") }}</v-btn
            >
          </div>
        </v-col>
        <v-col md="3" class="d-flex justify-end py-0">
          <v-avatar size="100" class="ml-5">
            <v-img
              :src="student.student_photo"
              v-if="student.student_photo"
              @click="openPhoto(student.student_photo)"
              style="cursor: pointer"
            ></v-img>
            <v-icon v-else size="150"> mdi-alert-octagram </v-icon>
          </v-avatar>
        </v-col>
        <v-col md="12" class="py-0 mb-10">
          <v-row>
            <template v-for="(info, key, index) of student.information">
              <v-col md="4" :key="index" v-if="info.value">
                <h3 class="ml-5">
                  {{ $t(info.description) }} :
                  <span style="font-weight: 100">{{
                    info.value == "female"
                      ? $t(info.value)
                      : info.value == "male"
                      ? $t(info.value)
                      : info.value == "new"
                      ? $t(info.value)
                      : info.value == "transported"
                      ? $t(info.value)
                      : info.value
                  }}</span>
                </h3>
              </v-col>
            </template>
          </v-row>
        </v-col>
      </v-row>
      <v-card >
        <v-toolbar  :dark="$store.state.isDarkMode">
          <template >
            <v-tabs v-model="tab" align-with-title color="#757575">
              <v-tabs-slider></v-tabs-slider>
              <v-tab :disabled="disableTabs" :href="'#' + '1'">{{
                $t("photos")
              }}</v-tab>
              <v-tab
                :disabled="disableTabs"
                :href="'#' + '2'"
                v-if="returnAbility('registration-record:data')"
              >
                {{ $t("Registration information") }}
              </v-tab>
              <v-tab
                :disabled="disableTabs"
                :href="'#' + '3'"
                v-if="returnAbility('note:index')"
              >
                {{ $t("notes") }}
              </v-tab>
              <v-tab
               
                v-if="returnAbility('account:statement')"
                @click="$router.push(`/accountStatement/${statementId}`);"
              >
                {{ $t("Account statement") }}
              </v-tab>
              <v-tab
                :disabled="disableTabs"
                :href="'#' + '5'"
                v-if="returnAbility('test:index')"
              >
                {{ $t("The studies") }}
              </v-tab>
              <v-tab
                @click="displayMainBuffet"
                :disabled="disableTabs"
                :href="'#' + '6'"
                v-if="returnAbility('buffet-card:show')"
              >
                {{ $t("the buffet") }}
              </v-tab>
              <v-tab
                :disabled="disableTabs"
                :href="'#' + '7'"
                v-if="returnAbility('absent:index')"
              >
                {{ $t("absence") }}
              </v-tab>
              <v-tab
                :href="'#' + '8'"
              >
              {{ $t("Variables") }}
               
              </v-tab>
              <v-tab
                :href="'#' + '9'"
              >
              {{ $t("Mobile App") }}
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>

        <v-tabs-items :dark="$store.state.isDarkMode" v-model="tab">
          <v-tab-item value="1">
            <app-record-pictures
              v-if="tab == '1'"
              :pictures="student.minorPhotos"
            ></app-record-pictures>
          </v-tab-item>
          <v-tab-item value="2">
            <app-record-register-info
              v-if="tab == '2' && returnAbility('registration-record:data')"
              :student="student"
              :registerStatus="registerStatus"
            ></app-record-register-info>
          </v-tab-item>
          <v-tab-item value="3">
            <app-record-notes
              v-if="tab == '3' && returnAbility('note:index')"
              :registerStatus="registerStatus"
              :student="student"
            ></app-record-notes>
          </v-tab-item>
       
            <!-- <v-tab-item  value="4">
            
              <app-record-account-statement
                v-if="tab == '4' && returnAbility('account:statement')"
              ></app-record-account-statement>
        
            
          </v-tab-item> -->
        
          <v-tab-item value="5">
            <app-tests
              :registerStatus="registerStatus"
              v-if="tab == '5' && returnAbility('test:index')"
            ></app-tests>
          </v-tab-item>
          <v-tab-item value="6">
            <app-buffet
              v-if="
                tab == '6' &&
                returnAbility('buffet-card:show') &&
                displayMainBuffetData
              "
              @displayDepositRecord="displayDeposit"
              @displayPurchaseRecord="displayPurchase"
            ></app-buffet>
            <app-deposit-record
              v-if="displayDepositBuffetData"
            ></app-deposit-record>
            <app-purchase-record
              v-if="displayPurchaseBuffetData"
            ></app-purchase-record>
          </v-tab-item>
          <v-tab-item value="7">
            <app-absents
              :registerStatus="registerStatus"
              v-if="tab == '7' && returnAbility('absent:index')"
            ></app-absents>
          </v-tab-item>
          <v-tab-item value="8">
           <app-variables  v-if="tab == '8'"></app-variables>
          </v-tab-item>
          <v-tab-item value="9">
            <app-mobile-app
              :registerStatus="registerStatus"
              :student="student"
              v-if="tab == '9' && returnAbility('absent:index')"
            ></app-mobile-app>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
  </v-card>
</v-container>
</template>

<script>
import spinner from "../../../../../components/base/spinner.vue";
import acceptStudentModal from "../acceptStudentModal.vue";
import editAcceptStudentModal from "../editAcceptStudentModal.vue";
import recordPictures from "./components/recordPictures.vue";
import recordRegisterInfo from "./components/recordRegisterInfo.vue";
import recordNotes from "./components/recordNotes.vue";
import recordAccountStatement from "./components/recordAccountStatement.vue";
import absents from "./components/absents.vue";
import tests from "./components/tests.vue";
import buffet from "./components/buffet/buffet.vue";
import depositRecord from "./components/buffet/despositRecord.vue";
import purchaseRecord from "./components/buffet/purchaseRecord.vue";
import mobileApp from "./components/mobileApp.vue";
import variables from "./components/variables.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    appSpinner: spinner,
    appRecordNotes: recordNotes,
    appRecordAccountStatement: recordAccountStatement,
    appRecordRegisterInfo: recordRegisterInfo,
    appRecordPictures: recordPictures,
    appEditAcceptStudentModal: editAcceptStudentModal,
    appAcceptStudentModal: acceptStudentModal,
    appAbsents: absents,
    appTests: tests,
    appBuffet: buffet,
    appDepositRecord: depositRecord,
    appPurchaseRecord: purchaseRecord,
    appMobileApp: mobileApp,
    appVariables: variables,
  },
  data() {
    return {
      tab: false,
      studentId: this.$route.params.id,
      statementId: this.$route.params.statementId,
      displayMainBuffetData: false,
      displayDepositBuffetData: false,
      displayPurchaseBuffetData: false,
    };
  },
  watch: {
    "$route.params.id": function () {
      this.studentId = this.$route.params.id;
      this.statementId = this.$route.params.statementId;
      this.getRegistrationRecord(this.studentId);
      },
  },
  computed: {
    ...mapGetters({
      loadingStatus: "registrationRecord/getLoadingStatus",
      registerStatus: "registrationRecord/getStudentRegisterStatus",
      student: "registrationRecord/getStudentData",
      getTableLoadingStatus: "registrationRecord/getTableLoading",
    }),
    disableTabs() {
      if (this.getTableLoadingStatus) {
        return true;
      } else {
        return false;
      }
    },

  },
  methods: {
    displayDeposit() {
      this.displayMainBuffetData = false;
      this.displayDepositBuffetData = true;
    },
    displayPurchase() {
      this.displayMainBuffetData = false;
      this.displayPurchaseBuffetData = true;
    },
    displayMainBuffet() {
      this.displayMainBuffetData = true;
      this.displayDepositBuffetData = false;
      this.displayPurchaseBuffetData = false;
    },
    ...mapActions({
      getRegistrationRecord: "registrationRecord/fetchRegistrationRecord",
    }),

    openPhoto(url) {
      window.open(url);
    },
  },
  created() {
    
    this.getRegistrationRecord(this.studentId);
  },
};
</script>

<style>
.registration-record__img-wrapper {
  width: 150px;
  height: auto;
  cursor: pointer;
}
.registration-record__img {
  width: 100%;
}

/* .registration-record__spinner-wrapper {
  height: 85vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
} */
</style>
