<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="!returnAbility('buffet-card:store')"
          large
          class="white--text"
          color="green"
          rounded
          v-on="on"
          v-bind="attrs"
          >إضافة بطاقة</v-btn
        >
      </template>
      <v-card>
        <v-card-title class="justify-center">
          <h1>إضافة بطاقة</h1>
        </v-card-title>
        <v-card-text>
          <v-text-field
            label="رقم البطاقة"
            outlined
            dense
            v-model="serial"
          ></v-text-field>
          <h4 v-if="cardStatus">
            ملاحظة: سيتم إلغاء البطاقة القديمة عند إضافة بطاقة جديدة
          </h4>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            class="white--text"
            color="green"
            depressed
            @click="addCard"
            :loading="btnLoading"
            >{{ $t('add') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      studentId: this.$route.params.id,
      dialog: false,
      serial: null,
      btnLoading: false,
    };
  },
  props: {
    cardStatus: {
      type: Boolean,
    },
  },
  methods: {
    async addCard() {
      try {
        this.btnLoading = true;
        await axios.post("/buffet-card", {
          serial: this.serial,
          student_id: this.studentId,
        });
        this.$Notifications(
          "تم إضافة البطاقة",
          { rtl: true, timeout: 2000 },
          "success"
        );
        this.dialog = false;
        this.$emit("cardAdded");
      } catch (err) {
        console.log("err", err);
      } finally {
        this.btnLoading = false;
      }
    },
  },
};
</script>

<style>
</style>